import React from 'react'
import { PageProps } from 'gatsby'
import { Head, Link, Login } from '~/components'

const CreatePassword = ({ location }: PageProps) => {
  return (
    <>
      <Head location={location} />
      <main className="bkg-main">
        <Login
          title="Criar senha de acesso"
          input={[
            {
              label: 'CNPJ',
              name: 'login',
              mask: 'cnpj',
              type: 'text',
              readonly: 'readonly',
              value: `${location.search.substring(1).split('&cnpj=')[1]}`,
            },
            {
              label: 'Digite sua senha',
              name: 'password',
              type: 'password',
            },
            {
              label: 'Repita sua senha',
              name: 'repassword',
              type: 'password',
            },
          ]}
          btnRequest="Salvar"
          btnLoading="Carregando..."
          nextPage="senha-alterada"
          location={location}
          path={'change-password'}
        />
      </main>
    </>
  )
}

export default CreatePassword
